import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/docs',
    name: 'docs',
    component: () => import('../views/DocsView.vue')
  },
  {
    path: '/materials',
    name: 'materials',
    component: () => import('../views/MaterialsView.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/NewsView.vue')
  },
  {
    path: '/competitors',
    name: 'competitors',
    component: () => import('../views/CompetitorsView.vue')
  },
  {
    path: '/newsbig',
    name: 'newsbig',
    component: () => import('../views/NewsBigView.vue')
  },
  {
    path: '/test',
    name: 'test',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/TestView.vue')
  }

]

const router = new VueRouter({
  routes
})

export default router
