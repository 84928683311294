<template>
  <v-container fluid class="main">

    <!-- Блок с картиночкой --- блок с картиночкой  -------------------- -->

    <v-row>
      <v-col class="mainpic">
        <v-row>
          <v-col class="pt-0 mainpicpic">
            <v-img class="mainpiccity" src="@/assets/main.png" ></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="bluerect">
          </v-col>
        </v-row>
        <v-row v-if="config">
          <v-col class="bluerectframe">
            <v-row>
              <v-col class="bluerecttext" v-html="config.description" />
            </v-row>
            <v-row>
              <v-col>
                <div class="bluerectbutton">
                  <v-btn class="buttonitself" elevation="0" color="#EABD6B" rounded>
                    <a class="reg-btn" :href="config.reg_link" target="_blank" v-if="checkDate"> РЕГИСТРАЦИЯ </a>
                    <a class="reg-btn"  v-if="!checkDate"> 
                      <span v-if="checkCloseDate"> РЕГИСТРАЦИЯ ОТКРОЕТСЯ {{ config.day_reg }} {{ mon(config.mon_reg) }} </span>
                      <span v-if="!checkCloseDate"> РЕГИСТРАЦИЯ ЗАКРЫТА </span>
                    </a>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Новостные карточки новостные карточки ------------------------- -->

    <v-row>
      <v-col cols="12" class="novosti mt-3 d-block d-md-none">
        НОВОСТИ
      </v-col>
    </v-row>

    <v-row>
      <v-col class="newscards">

        <!--  этот  блок  для  экранов  БОЛЬШЕ  1024  ------------------- -->

        <v-row class="d-none d-md-flex">
          <v-col md="6" lg="4" v-for="(item, i) in news" :key="i">
            <v-card elevation="4" class="card ma-3 justify-space-between">
              <v-row>
                <v-col class="newsheader">
                  {{ item.header }}
                </v-col>
                <div class="datebadge">
                  <v-row>
                    <v-col>
                      <div class="date">
                        {{ item.day }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col >
                      <div class="month">
                        {{ mon(item.mon) }}
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-row>
              <hr style="opacity: 75%; margin-left: 35px; margin-right: 35px; margin-top: 15px; margin-bottom: 15px">
              <v-row>
                <v-col>
                  <div class="newstext">
                    {{item.body}}
                  </div>
                  <div class="mr-0 mr-md-3 text-right">
                    <router-link :to="{name: 'newsbig', params: {id: item.id}}"> подробнее >></router-link>
                  </div>
                </v-col>

              </v-row>
              <v-row>
                <v-col class="pb-0">
                  <v-img :src="'http://сердцедетям.рф/api/storage/app/media'+item.pic" class="newscardspic"></v-img>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <!-- этот блок для экранов от 600 до 960      ------------------- -->

        <v-row class="vos d-none d-sm-flex d-md-none">
          <v-col md="6" lg="4" v-for="(item, i) in news" :key="i">
            <v-card elevation="4" class="card ma-3 justify-space-between">
              <v-row>
                <v-col cols="8" class="py-0">
                  <v-row>
                    <v-col class="newsheader">
                      {{ item.header }}
                    </v-col>
                  </v-row>
                  <hr class="divider">
                  <v-row>
                    <v-col>
                      <div class="newstext">
                        {{item.body}}
                      </div>
                      <div class="newstext mr-0 mr-sm-6 mr-md-3 text-right">
                        <router-link :to="{name: 'newsbig', params: {id: item.id}}"> подробнее >></router-link>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="4">
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <v-img :src="'http://сердцедетям.рф/api/storage/app/media'+item.pic" class="newscardspic"></v-img>
                      <div class="datebadge">
                        <v-row>
                          <v-col>
                            <div class="date">
                              {{ item.day }}
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col >
                            <div class="month">
                              {{ mon(item.mon) }}
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <!-- этот блок для экранов меньше 600 и 420  -------------------- -->

        <v-row class="d-flex d-sm-none">
          <v-col cols="12" v-for="(item, i) in news" :key="i">
            <v-card elevation="4" class="card ma-3 justify-space-between">
              <v-row>
                <v-col>
                  <div class="newsheader">
                    {{ item.header }}
                  </div>
                  <hr class="divider">
                  <div class="newstext">
                    {{item.body}}
                  </div>
                  <div class="newstext mb-0 mb-md-3 mr-0 mr-md-3 mr-5 text-right">
                    <router-link :to="{name: 'newsbig', params: {id: item.id}}"> подробнее >></router-link>
                  </div>
                </v-col>
                <v-col>
                  <div class="datebadge">
                    <v-row>
                      <v-col class="date">
                        {{ item.day }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="month">
                        {{ mon(item.mon) }}
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

      </v-col>
    </v-row>

    <!--     нижний блок с логотипами          нижний блок с логотипами          нижний блок с логотипами  -->
    <!--     блок для 1 9 2 0 +++++++++++ 1 4 4 0    -->

    <v-row class="mb-5 mt-14 d-none d-lg-flex" no-gutters>

      <v-col cols="12" md="3" class="orgs mb-5" style="border-right: 1px solid #ccc;">
        <v-row>
          <v-col class="justify-center">
            Учредитель конкурса:
          </v-col>
        </v-row>
        <v-row>
          <v-col v-for="(item, i) in uchreditFiltering" :key="i">
            <a :href="item.link"><img :src="item.logo.path" style="max-width: 185px; max-height: 135px" /></a>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="6" class="orgs px-5 mb-5" style="border-right: 1px solid #ccc;">
        <v-row>
          <v-col class="justify-center">
            Организаторы конкурса:
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-row class="justify-center align-center">
              <v-col cols="4" class="mx-0 px-0" v-for="(item, i) in orgsFiltering" :key="i">
                <a :href="item.link"><img :src="item.logo.path" style="max-width: 105px; max-height: 105px" /></a>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="3" class="orgs">
        <v-row>
          <v-col>
            Оператор конкурса:
          </v-col>
        </v-row>
        <v-row>
          <v-col v-for="(item, i) in operatorFiltering" :key="i">
            <a :href="item.link"><img :src="item.logo.path" style="max-width: 125px; max-height: 125px" /></a>
          </v-col>
        </v-row>
      </v-col>

    </v-row>

    <!--     блок для 1 0 2 4 ++++++++++ 8 0 0     -->

    <v-row class="mb-5 mt-14 ma-0 pa-0 d-none d-sm-flex d-lg-none justify-space-around" no-gutters>

      <v-col cols="4" class="orgs mb-5 pr-16" style="border-right: 1px solid #ccc;">
        <v-row>
          <v-col class="justify-center">
            Учредитель конкурса:
          </v-col>
        </v-row>
        <v-row>
          <v-col v-for="(item, i) in uchreditFiltering" :key="i" class="justify-center">
            <a :href="item.link"><img :src="item.logo.path" style="min-width: 100px; min-height: 75px; max-width: 150px; max-height: 102px" /></a>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="justify-center">
            Оператор конкурса:
          </v-col>
        </v-row>
        <v-row>
          <v-col v-for="(item, i) in operatorFiltering" :key="i" class="justify-center">
            <a :href="item.link"><img :src="item.logo.path" style="min-width: 100px; min-height: 75px; max-width: 150px; max-height: 102px" /></a>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="8" class="orgs px-0 mx-0 mb-5 align-center">
        <v-row>
          <v-col class="justify-center">
            Организаторы конкурса:
          </v-col>
        </v-row>
        <v-row>
          <v-col>

          </v-col>
        </v-row>
        <v-row class="justify-center align-center mt-2">
          <v-col cols="4" v-for="(item, i) in orgsFiltering" :key="i" class="pa-0 mx-0">
            <a :href="item.link"><img :src="item.logo.path" style="max-width: 90px; max-height: 90px" /></a>
          </v-col>
        </v-row>
      </v-col>

    </v-row>

    <!--     блок для 4  2  0 и меньше     -->

    <v-row class="mb-5 mt-14 ma-0 pa-0 d-flex d-sm-none justify-space-around" no-gutters>
      <v-col cols="12" class="orgs px-0 mb-5 align-center">
        <v-row class="justify-center">
          <v-col class="justify-center">
            Организаторы конкурса:
          </v-col>
        </v-row>
        <v-row class="justify-center align-center mt-2">
          <v-col cols="4" v-for="(item, i) in orgsFiltering" :key="i" class="pa-0 mt-4">
            <a :href="item.link"><img :src="item.logo.path" style="max-width: 95px; max-height: 95px" /></a>
          </v-col>
        </v-row>
      </v-col>

    </v-row>

    <v-row class="orgs mb-5 mt-8 ma-0 pa-0 d-flex d-sm-none justify-space-between" no-gutters>
      <v-col>
        <v-row>
          <v-col class="justify-center">
            Оператор конкурса:
          </v-col>
        </v-row>
        <v-row>
          <v-col v-for="(item, i) in operatorFiltering" :key="i">
            <a :href="item.link"><img :src="item.logo.path" style="max-width: 74px; max-height: 73px" /></a>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-row>
          <v-col class="justify-center">
            Учредитель конкурса:
          </v-col>
        </v-row>
        <v-row>
          <v-col v-for="(item, i) in uchreditFiltering" :key="i" class="justify-center">
            <a :href="item.link"><img :src="item.logo.path" style="max-width: 125px; max-height: 74px" /></a>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: "TestView",
  data() {
    return {
      news: [],
      config: null,
      partners: [],
      month: [
      "января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"
      ]
    }
  },

  mounted() {

    axios
        .get('http://сердцедетям.рф/api/news/')
        .then(response => (this.news = response.data))

    axios
        .get('http://сердцедетям.рф/api/partners/')
        .then(response => (this.partners = response.data))

    axios
        .get('http://сердцедетям.рф/api/config/')
        .then(response => (this.config = response.data))
  },

  computed: {
    uchreditFiltering() {
      return this.partners.filter(item => item.type === 3)
    },

    operatorFiltering() {
      return this.partners.filter(item => item.type === 2)
    },

    orgsFiltering() {
      return this.partners.filter(item => item.type === 1)
    },
    checkDate() {
      return ((Date.now() > (new Date(this.config.reg_open).getTime()))&&(Date.now() < (new Date(this.config.reg_close).getTime())))
    },
    checkCloseDate() {
      return Date.now() < (new Date(this.config.reg_close).getTime())
    }
  },
  methods: {
    mon(mon) {
      return this.month[mon-1]
    },
  },
}
</script>

<style scoped>

/* 1920+ 1920+ 1920+ 1920+ 1920+ 1920+ 1920+ 1920+ 1920+ 1920+ 1920+ 1920+ 1920+ 1920+ 1920+ 1920+ 1920+ 1920+ 1920+ */

.main {
  width: 1248px;
  position: relative;
}

.mainpic {
  position: relative;
  height: 959px;
}

.mainpicpic {
  position: relative;
}

.mainpiccity {
  min-width: 1920px;
  min-height: 959px;
  left: -340px;
}

.bluerect {
  background-color: #125CA0;
  opacity: 76%;
  height: 477px;
  position: absolute;
  top: 92px;
}

.bluerectframe {
  border: 1px solid #FFFFFF;
  width: 96%;
  height: 425px;
  z-index: 3;
  position: absolute;
  top: 117px;
  left: 24px;
}

.bluerectframegpinimg{
  margin-top: 38px;
  margin-bottom: 5px;
  max-height: 96px;
  max-width: 105px;
}

.bluerecttext{
  font-family: "Slange Bold";
  color: #FFFFFF;
  opacity: 100;
  font-size: 34px;
  line-height: 42px;
  text-align: center;
  padding-top: 125px;
}

.bluerectbutton{
  font-family: "Roboto Regular";
  font-size: 24px;
  font-weight: 700;
  margin-top: 10px;
}

.buttonitself {
  font-family: "Slange Bold";
  text-decoration: none;
  color: #333;
}

.reg-btn {
  text-decoration: none;
  text-transform: uppercase;
}

/* блок карточек блок карточек блок карточек блок карточек блок карточек блок карточек блок карточек */

.newscards{
  position: relative;
  margin-top: -300px;
}

.card{
  border-radius: 15px;
  min-height: 485px;
}

.newsheader{
  min-width: 256px;
  min-height: 100px;
  font-family: 'Slange Bold';
  font-size: 20px;
  line-height: 20px;
  color: #3D3A3A;
  text-align: left;
  margin: 15px;
  margin-left: 22px;
}

.newstext{
  min-width: 250px;
  max-width: 100%;
  min-height: 130px;
  font-family: 'Roboto Regular';
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  text-align: left;
  margin: 15px;
  margin-left: 22px;
}

.newscardspic{
  max-width: 100%;
  max-height: 245px;
  border-radius: 0 0 15px 15px;
}

/* бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж */

.datebadge{
  color: #FFFFFF;
  background-color: #6A9ED7;
  border-radius: 0 0 0 40px;
  width: 87px;
  height: 98px;
  position: relative;
  left: -33px;
}

.date{
  position: relative;
  font-size: 32px;
  font-family: 'Slange Bold';
  top: 10px;
}

.month{
  position: relative;
  font-size: 16px;
  font-family: 'Slange Bold';
  margin-top: -25px;
}

/* организоаторы
                              организаторы */

.orgs{
  position: relative;
  font-family: "Slange Bold";
  font-size: 20px;
  text-align: center;
}

/* 1920 1920 1920 1920 1920 1920 1920 1920 1920 1920 1920 1920 1920 1920 1920 1920 1920 1920 1920 1920 1920 1920 1920 */

@media all and (max-width: 1920px) {

  .main {
    width: 1248px;
    position: relative;
  }

  .mainpic {
    position: relative;
    height: 959px;
  }

  .mainpicpic {
    position: relative;
  }

  .mainpiccity {
    min-width: 1920px;
    min-height: 959px;
    left: -340px;
  }

  .bluerect {
    background-color: #125CA0;
    opacity: 76%;
    height: 477px;
    position: absolute;
    top: 92px;
  }

  .bluerectframe {
    border: 1px solid #FFFFFF;
    width: 96%;
    height: 425px;
    z-index: 3;
    position: absolute;
    top: 117px;
    left: 24px;
  }

  .bluerectframegpinimg{
    margin-top: 38px;
    margin-bottom: 5px;
    max-height: 96px;
    max-width: 105px;
  }

  .bluerecttext{
    font-family: "Slange Bold";
    color: #FFFFFF;
    opacity: 100;
    font-size: 34px;
    line-height: 42px;
    text-align: center;
  }

  .bluerectbutton{
    font-family: "Roboto Regular";
    font-size: 24px;
    font-weight: 700;
    margin-top: 10px;
  }

  .buttonitself {
    font-family: "Slange Bold";
    text-decoration: none;
    color: #333;
  }

  /* блок карточек блок карточек блок карточек блок карточек блок карточек блок карточек блок карточек */

  .newscards{
    position: relative;
    margin-top: -300px;
  }

  .card{
    border-radius: 15px;
    min-height: 485px;
  }

  .newsheader{
    min-width: 256px;
    min-height: 100px;
    font-family: 'Slange Bold';
    font-size: 20px;
    line-height: 20px;
    color: #3D3A3A;
    text-align: left;
    margin: 15px;
    margin-left: 22px;
  }

  .newstext{
    min-width: 250px;
    max-width: 100%;
    min-height: 130px;
    font-family: 'Roboto Regular';
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    text-align: left;
    margin: 15px;
    margin-left: 22px;
  }

  .newscardspic{
    max-width: 100%;
    max-height: 245px;
    border-radius: 0 0 15px 15px;
  }

  /* бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж */

  .datebadge{
    color: #FFFFFF;
    background-color: #6A9ED7;
    border-radius: 0 0 0 40px;
    width: 87px;
    height: 98px;
    position: relative;
    left: -33px;
  }

  .date{
    position: relative;
    font-size: 32px;
    font-family: 'Slange Bold';
    top: 10px;
  }

  .month{
    position: relative;
    font-size: 16px;
    font-family: 'Slange Bold';
    margin-top: -25px;
  }

  /* организоаторы
                                организаторы */

  .orgs{
    position: relative;
    font-family: "Slange Bold";
    font-size: 20px;
    text-align: center;
  }

}

/* 1440 1440 1440 1440 1440 1440 1440 1440 1440 1440 1440 1440 1440 1440 1440 1440 1440 1440 1440 1440 1440 1440 1440 */

@media all and (max-width: 1440px) {

  .main {
    width: 1248px;
    position: relative;
  }

  .mainpic {
    position: relative;
    height: 942px;
  }

  .mainpicpic {
    position: relative;
  }

  .mainpiccity {
    min-width: 1680px;
    min-height: 942px;
    left: -340px;
  }

  .bluerect {
    background-color: #125CA0;
    opacity: 76%;
    height: 477px;
    position: absolute;
    top: 92px;
  }

  .bluerectframe {
    border: 1px solid #FFFFFF;
    width: 96%;
    height: 425px;
    z-index: 3;
    position: absolute;
    top: 117px;
    left: 24px;
  }

  .bluerectframegpinimg{
    margin-top: 35px;
    margin-bottom: 5px;
    max-height: 96px;
    max-width: 105px;
  }

  .bluerecttext{
    font-family: "Slange Bold";
    color: #FFFFFF;
    opacity: 100;
    font-size: 34px;
    line-height: 42px;
    text-align: center;
  }

  .bluerectbutton{
    font-family: "Roboto Regular";
    font-size: 24px;
    font-weight: 700;
    margin-top: 10px;
  }
  .reg-btn{
    font-family: "Slange Bold";
    text-decoration: none !important;
    color: #333;
    text-transform: uppercase;
  }

  /* блок карточек блок карточек блок карточек блок карточек блок карточек блок карточек блок карточек */

  .newscards{
    position: relative;
    margin-top: -300px;
  }

  .card{
    border-radius: 15px;
    min-height: 485px;
  }

  .newsheader{
    min-width: 256px;
    min-height: 100px;
    font-family: 'Slange Bold';
    font-size: 20px;
    line-height: 20px;
    color: #3D3A3A;
    text-align: left;
    margin: 15px;
    margin-left: 22px;
  }

  .newstext{
    min-width: 250px;
    max-width: 100%;
    min-height: 130px;
    font-family: 'Roboto Regular';
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    text-align: left;
    margin: 15px;
    margin-left: 22px;
  }

  .newscardspic{
    max-width: 100%;
    max-height: 245px;
    border-radius: 0 0 15px 15px;
  }

  /* бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж */

  .datebadge{
    color: #FFFFFF;
    background-color: #6A9ED7;
    border-radius: 0 0 0 40px;
    width: 87px;
    height: 98px;
    position: relative;
    left: -33px;
  }

  .date{
    position: relative;
    font-size: 32px;
    font-family: 'Slange Bold';
    top: 10px;
  }

  .month{
    position: relative;
    font-size: 16px;
    font-family: 'Slange Bold';
    margin-top: -25px;
  }

  /* организоаторы
                                организаторы */

  .orgs{
    position: relative;
    font-family: "Slange Bold";
    font-size: 20px;
    text-align: center;
  }

}

/* 1260 1260 1260 1260 1260 1260 1260 1260 1260 1260 1260 1260 1260 1260 1260 1260 1260 1260 1260 1260 1260 1260 */

@media all and (max-width: 1264px) {

  .main {
    width: 1000px;
    position: relative;
  }

  .mainpic {
    position: relative;
    height: 935px;
  }

  .mainpicpic {
    position: relative;
  }

  .mainpiccity {
    min-width: 1460px;
    min-height: 965px;
    left: -340px;
  }

  .bluerect {
    background-color: #125CA0;
    opacity: 76%;
    height: 477px;
    position: absolute;
    top: 92px;
  }

  .bluerectframe {
    border: 1px solid #FFFFFF;
    width: 95%;
    height: 425px;
    z-index: 3;
    position: absolute;
    top: 117px;
    left: 24px;
  }

  .bluerectframegpinimg{
    margin-top: 33px;
    margin-bottom: 5px;
    max-width: 86px;
    max-height: 79px;
  }

  .bluerecttext{
    font-family: "Slange Bold";
    color: #FFFFFF;
    opacity: 100;
    font-size: 28px;
    line-height: 42px;
    text-align: center;
  }

  .bluerectbutton{
    font-family: "Roboto Regular";
    font-size: 24px;
    font-weight: 700;
    margin-top: 20px;
  }

  .reg-btn {
    font-family: "Slange Bold";
    text-decoration: none;
    color: #333;
  }

  /* блок карточек блок карточек блок карточек блок карточек блок карточек блок карточек блок карточек */

  .newscards{
    position: relative;
    margin-top: -300px;
  }

  .card{
    border-radius: 15px;
    min-height: 485px;
  }

  .newsheader{
    min-width: 256px;
    min-height: 90px;
    font-family: 'Slange Bold';
    font-size: 20px;
    line-height: 20px;
    color: #3D3A3A;
    text-align: left;
    margin: 15px;
    margin-left: 22px;
  }

  .newstext{
    min-width: 250px;
    max-width: 100%;
    min-height: 100px;
    font-family: 'Roboto Regular';
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    text-align: left;
    margin: 15px;
    margin-left: 22px;
  }

  .newscardspic{
    max-width: 100%;
    max-height: 245px;
    border-radius: 0 0 15px 15px;
  }

  /* бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж */

  .datebadge{
    color: #FFFFFF;
    background-color: #6A9ED7;
    border-radius: 0 0 0 40px;
    width: 87px;
    height: 98px;
    position: relative;
    left: -33px;
  }

  .date{
    position: relative;
    font-size: 32px;
    font-family: 'Slange Bold';
    top: 10px;
  }

  .month{
    position: relative;
    font-size: 16px;
    font-family: 'Slange Bold';
    margin-top: -25px;
  }

  /* организоаторы
                                организаторы */

  .orgs{
    position: relative;
    font-family: "Slange Bold";
    font-size: 20px;
    text-align: center;
    margin-top: 25px;
  }

}

/* 1024 1024 1024 1024 1024 1024 1024 1024 1024 1024 1024 1024 1024 1024 1024 1024 1024 1024 1024 1024 1024 1024 */

@media all and (max-width: 1024px) {

  .main {
    width: 826px;
    position: relative;
  }

  .mainpic {
    position: relative;
    height: 876px;
  }

  .mainpicpic {
    position: relative;
  }

  .mainpiccity {
    min-width: 1255px;
    min-height: 965px;
    left: -340px;
  }

  .bluerect {
    background-color: #125CA0;
    opacity: 76%;
    height: 465px;
    position: absolute;
    top: 92px;
  }

  .bluerectframe {
    border: 1px solid #FFFFFF;
    width: 94%;
    height: 418px;
    z-index: 3;
    position: absolute;
    top: 117px;
    left: 24px;
  }

  .bluerectframegpinimg{
    margin-top: 30px;
    margin-bottom: 5px;
    max-width: 86px;
    max-height: 79px;
  }

  .bluerecttext{
    font-family: "Slange Bold";
    color: #FFFFFF;
    opacity: 100;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
  }

  .bluerectbutton{
    font-family: "Roboto Regular";
    font-size: 24px;
    font-weight: 700;
    margin-top: 20px;
  }

  .reg-btn {
    font-family: "Slange Bold";
    text-decoration: none;
    color: #333;
  }

  /* блок карточек блок карточек блок карточек блок карточек блок карточек блок карточек блок карточек */

  .newscards{
    position: relative;
    margin-top: -250px;
  }

  .card{
    border-radius: 15px;
    min-height: 485px;
  }

  .newsheader{
    min-width: 256px;
    min-height: 90px;
    font-family: 'Slange Bold';
    font-size: 20px;
    line-height: 20px;
    color: #3D3A3A;
    text-align: left;
    margin: 15px;
    margin-left: 22px;
  }

  .newstext{
    min-width: 250px;
    max-width: 100%;
    min-height: 110px;
    font-family: 'Roboto Regular';
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    text-align: left;
    margin: 15px;
    margin-left: 22px;
  }

  .newscardspic{
    max-width: 100%;
    max-height: 245px;
    border-radius: 0 0 15px 15px;
  }

  /* бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж */

  .datebadge{
    color: #FFFFFF;
    background-color: #6A9ED7;
    border-radius: 0 0 0 40px;
    width: 87px;
    height: 98px;
    position: relative;
    left: -33px;
  }

  .date{
    position: relative;
    font-size: 32px;
    font-family: 'Slange Bold';
    top: 10px;
  }

  .month{
    position: relative;
    font-size: 16px;
    font-family: 'Slange Bold';
    margin-top: -25px;
  }

  /* организоаторы
                                организаторы */

  .orgs{
    position: relative;
    font-family: "Slange Bold";
    font-size: 20px;
    text-align: center;
    margin-top: 25px;
  }

}

/* 960 960 960 960 960 960 960 960 960 960 960 960 960 960 960 960 960 960 960 960 960 960 960 960 960 960 960 */

@media all and (max-width: 959px) {

  .main {
    width: 826px;
    position: relative;
  }

  .mainpic {
    position: relative;
    height: 860px;
  }

  .mainpicpic {
    position: relative;
  }

  .mainpiccity {
    min-width: 1225px;
    min-height: 959px;
    left: -340px;
  }

  .bluerect {
    background-color: #125CA0;
    opacity: 76%;
    height: 465px;
    position: absolute;
    top: 92px;
  }

  .bluerectframe {
    border: 1px solid #FFFFFF;
    width: 94%;
    height: 418px;
    z-index: 3;
    position: absolute;
    top: 117px;
    left: 24px;
  }

  .bluerectframegpinimg{
    margin-top: 25px;
    margin-bottom: 5px;
    max-width: 84px;
    max-height: 77px;
  }

  .bluerecttext{
    font-family: "Slange Bold";
    color: #FFFFFF;
    opacity: 100;
    font-size: 26px;
    line-height: 36px;
    text-align: center;
    padding-top: 100px;
  }

  .bluerectbutton{
    font-family: "Roboto Regular";
    font-size: 24px;
    font-weight: 700;
    margin-top: 40px;
  }

  .reg-btn {
    font-family: "Slange Bold";
    text-decoration: none;
    color: #333;
  }

  /* блок карточек блок карточек блок карточек блок карточек блок карточек блок карточек блок карточек */

  .novosti{
    position: relative;
    text-align: center;
    width: 76px;
    height: 25px;
    font-family: "Slange Bold";
    font-size: 20px;
    line-height: 25px;
    color: #ffffff;
    margin-bottom: 25px;
    display: none;
  }

  .newscards{
    position: relative;
    margin-top: -350px;
  }

  .card{
    border-radius: 15px;
    min-height: 200px;
    max-height: 240px;
  }

  .newsheader{
    min-width: 200px;
    max-width: 480px;
    min-height: 50px;
    font-family: 'Slange Bold';
    font-size: 20px;
    line-height: 20px;
    color: #3D3A3A;
    text-align: left;
    margin: 15px;
    margin-left: 22px;
  }

  .newstext{
    min-width: 200px;
    max-width: 480px;
    min-height: 50px;
    font-family: 'Roboto Regular';
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    text-align: left;
    margin: 15px;
    margin-left: 22px;
  }

  .newscardspic{
    max-width: 1245px;
    max-height: 240px;
    height: 240px;
    border-radius: 0 15px 15px 0;
  }

  .divider{
    opacity: 75%;
    margin-left: 35px;
    margin-right: 35px;
    margin-top: 15px;
    margin-bottom: 15px
  }

  /* бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж */

  .datebadge{
    color: #FFFFFF;
    background-color: #6A9ED7;
    border-radius: 0 0 0 40px;
    width: 87px;
    height: 98px;
    position: absolute;
    left: 85%;
    top: 0px;

  }

  .date{
    position: relative;
    font-size: 32px;
    font-family: 'Slange Bold';
    top: 10px;
  }

  .month{
    position: relative;
    font-size: 16px;
    font-family: 'Slange Bold';
    margin-top: -25px;
  }

  /* организоаторы
                                организаторы */

  .orgs{
    position: relative;
    font-family: "Slange Bold";
    font-size: 18px;
    text-align: center;
    margin-top: 25px;
  }

}

/* 800 800 800 800 800 800 800 800 800 800 800 800 800 800 800 800 800 800 800 800 800 800 800 800 800 800 800 800 */

@media all and (max-width: 800px) {

  .main {
    width: 740px;
    position: relative;
  }

  .mainpic {
    position: relative;
    height: 560px;
  }

  .mainpicpic {
    position: relative;
  }

  .mainpiccity {
    min-width: 1100px;
    min-height: 560px;
    max-height: 560px;
    left: -340px;
  }

  .bluerect {
    background-color: #125CA0;
    opacity: 76%;
    height: 465px;
    position: absolute;
    top: 50px;
  }

  .bluerectframe {
    border: 1px solid #FFFFFF;
    width: 94%;
    height: 418px;
    z-index: 3;
    position: absolute;
    top: 77px;
    left: 24px;
  }

  .bluerectframegpinimg{
    margin-top: 25px;
    margin-bottom: 5px;
    max-width: 83px;
    max-height: 76px;
  }

  .bluerecttext{
    font-family: "Slange Bold";
    color: #FFFFFF;
    opacity: 100;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  }

  .bluerectbutton{
    font-family: "Roboto Regular";
    font-size: 24px;
    font-weight: 700;
    margin-top: 40px;
  }

  .reg-btn {
    font-family: "Slange Bold";
    text-decoration: none;
    color: #333;
  }

  /* блок карточек блок карточек блок карточек блок карточек блок карточек блок карточек блок карточек */

  .novosti{
    position: relative;
    text-align: center;
    width: 76px;
    height: 25px;
    font-family: "Slange Bold";
    font-size: 20px;
    line-height: 25px;
    color: #3D3A3A;
    margin-bottom: 0px;
  }

  .newscards{
    position: relative;
    margin-top: 0px;
  }

  .card{
    border-radius: 15px;
    min-height: 150px;
    max-height: 240px;
  }

  .newsheader{
    min-width: 200px;
    max-width: 480px;
    min-height: 50px;
    font-family: 'Slange Bold';
    font-size: 16px;
    line-height: 20px;
    color: #3D3A3A;
    text-align: left;
    margin: 15px;
    margin-left: 22px;
  }

  .newstext{
    min-width: 200px;
    max-width: 480px;
    min-height: 10px;
    font-family: 'Roboto Regular';
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    margin: 15px;
    margin-left: 22px;
  }

  .newscardspic{
    max-width: 1000px;
    max-height: 240px;
    height: 209px;
    border-radius: 0 15px 15px 0;
  }

  .divider{
    opacity: 75%;
    margin-left: 22px;
    margin-right: 35px;
    margin-top: 5px;
    margin-bottom: 5px
  }

  /* бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж */

  .datebadge{
    color: #FFFFFF;
    background-color: #6A9ED7;
    border-radius: 0 0 0 20px;
    width: 12%;
    height: 55px;
    position: absolute;
    left: 85%;
    top: 0px;

  }

  .date{
    position: relative;
    font-size: 16px;
    font-family: 'Slange Bold';
    top: 5px;
  }

  .month{
    position: relative;
    font-size: 16px;
    font-family: 'Slange Bold';
    font-weight: 200;
    margin-top: -25px;
  }

  /* организоаторы
                                организаторы */

  .orgs{
    position: relative;
    font-family: "Slange Bold";
    font-size: 18px;
    text-align: center;
    margin-top: 25px;
  }

}

/* 600 600 600 600 600 600 600 600 600 600 600 600 600 600 600 600 600 600 600 600 600 600 600 600 600 600 600 600 */

@media all and (max-width: 600px) {

  .main {
    width: 538px;
    position: relative;
  }

  .mainpic {
    position: relative;
    height: 440px;
  }

  .mainpicpic {
    position: relative;
  }

  .mainpiccity {
    min-width: 900px;
    min-height: 440px;
    max-height: 440px;
    left: -340px;
  }

  .bluerect {
    background-color: #125CA0;
    opacity: 76%;
    height: 340px;
    position: absolute;
    top: 50px;
  }

  .bluerectframe {
    border: 1px solid #FFFFFF;
    width: 92%;
    height: 300px;
    z-index: 3;
    position: absolute;
    top: 70px;
    left: 22px;
  }

  .bluerecttext{
    font-family: "Slange Bold";
    color: #FFFFFF;
    opacity: 100;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    padding-top: 75px;
  }

  .bluerectbutton{
    font-family: "Roboto Regular";
    font-size: 24px;
    font-weight: 700;
    margin-top: 17px;
  }

  .reg-btn {
    font-family: "Slange Bold";
    text-decoration: none;
    color: #333;
  }

  /* блок карточек блок карточек блок карточек блок карточек блок карточек блок карточек блок карточек */

  .novosti{
    position: relative;
    text-align: center;
    width: 76px;
    height: 25px;
    font-family: "Slange Bold";
    font-size: 18px;
    line-height: 25px;
    color: #3D3A3A;
    margin-bottom: 0px;
  }

  .newscards{
    position: relative;
    margin-top: 0px;
  }

  .card{
    border-radius: 15px;
    min-height: 150px;
    max-height: 240px;
  }

  .newsheader{
    min-width: 220px;
    max-width: 260px;
    min-height: 50px;
    font-family: 'Slange Bold';
    font-size: 16px;
    line-height: 20px;
    color: #3D3A3A;
    text-align: left;
    margin: 15px;
    margin-left: 22px;
  }

  .newstext{
    min-width: 310px;
    max-width: 440px;
    min-height: 10px;
    font-family: 'Roboto Regular';
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    margin: 15px;
    margin-left: 22px;
  }

  .newscardspic{
    max-width: 1000px;
    max-height: 240px;
    height: 213px;
    border-radius: 0 15px 15px 0;
  }

  .divider{
    opacity: 75%;
    margin-left: 22px;
    margin-right: 35px;
    margin-top: 0px;
    margin-bottom: 0px
  }

  /* бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж */

  .datebadge{
    color: #FFFFFF;
    background-color: #6A9ED7;
    border-radius: 0 0 0 20px;
    width: 15%;
    height: 65px;
    position: absolute;
    left: 80%;
    top: 0px;

  }

  .date{
    position: relative;
    font-size: 16px;
    font-family: 'Slange Bold';
    top: 10px;
  }

  .month{
    position: relative;
    font-size: 16px;
    font-family: 'Slange Bold';
    font-weight: 200;
    margin-top: -15px;
  }

  /* организоаторы
                                организаторы */

  .orgs{
    position: relative;
    font-family: "Slange Bold";
    font-size: 18px;
    text-align: center;
    margin-top: 25px;
  }

}

/* 420 420 420 420 420 420 420 420 420 420 420 420 420 420 420 420 420 420 420 420 420 420 420 420 420 420 420 420 */

@media all and (max-width: 420px) {

  .main {
    width: 372px;
    position: relative;
  }

  .mainpic {
    position: relative;
    height: 300px;
  }

  .mainpicpic {
    position: relative;
  }

  .mainpiccity {
    min-width: 730px;
    min-height: 295px;
    max-height: 295px;
    left: -340px;
  }

  .bluerect {
    background-color: #125CA0;
    opacity: 76%;
    height: 248px;
    position: absolute;
    top: 25px;
  }

  .bluerectframe {
    border: 1px solid #FFFFFF;
    width: 91%;
    height: 226px;
    z-index: 3;
    position: absolute;
    top: 37px;
    left: 18px;
  }

  .bluerecttext{
    font-family: "Slange Bold";
    color: #FFFFFF;
    opacity: 100;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    padding-top: 35px;
  }

  .bluerectbutton{
    font-family: "Roboto Regular";
    font-size: 14px;
    font-weight: 700;
    margin-top: 2px;
  }

  .reg-btn {
    font-family: "Slange Bold";
    text-decoration: none;
    color: #333;
  }

  .buttonitself{
    max-height: 26px;
    max-width: 314px;
  }

  /* блок карточек блок карточек блок карточек блок карточек блок карточек блок карточек блок карточек */

  .novosti{
    position: relative;
    text-align: center;
    width: 76px;
    height: 20px;
    font-family: "Slange Bold";
    font-size: 16px;
    line-height: 25px;
    color: #3D3A3A;
    margin-bottom: 0px;
  }

  .newscards{
    position: relative;
    margin-top: 0px;
  }

  .card{
    border-radius: 15px;
    min-height: 125px;
    max-height: 240px;
  }

  .newsheader{
    min-width: 33%;
    max-width: 249px;
    width: 66%;
    min-height: 20px;
    max-height: 40px;
    font-family: 'Slange Bold';
    font-size: 12px;
    line-height: 14px;
    color: #3D3A3A;
    text-align: left;
    margin: 15px;
    margin-left: 22px;
  }

  .newstext{
    min-width: 250px;
    max-width: 300px;
    min-height: 10px;
    font-family: 'Roboto Regular';
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    text-align: left;
    margin: 15px;
    margin-left: 22px;
  }

  .newscardspic{
    max-width: 1000px;
    max-height: 240px;
    height: 209px;
    border-radius: 0 15px 15px 0;
  }

  .divider{
    opacity: 75%;
    margin-left: 22px;
    margin-right: 75px;
    margin-top: 0px;
    margin-bottom: 0px
  }

  /* бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж бадж */

  .datebadge{
    color: #FFFFFF;
    background-color: #6A9ED7;
    border-radius: 0 0 0 20px;
    width: 20%;
    height: 65px;
    position: absolute;
    left: 75%;
    top: 0px;
  }

  .date{
    position: relative;
    font-size: 12px;
    font-family: 'Slange Bold';
    top: 10px;
  }

  .month{
    position: relative;
    font-size: 12px;
    font-family: 'Slange Bold';
    font-weight: 200;
    margin-top: -15px;
  }

  /* организоаторы
                                организаторы */

  .orgs{
    position: relative;
    font-family: "Slange Bold";
    font-size: 12px;
    text-align: center;
    margin-top: 25px;
  }

}

</style>
