<template>
  <v-app>
    <v-container fluid class="header">
      <v-row no-gutters class="menu" v-if="config">
        <div class="logo mt-2 mt-sm-7 pl-1 pl-sm-3 pl-lg-0">
          <router-link to="/"><img :src="config.logo.path" /></router-link>
        </div>

        <v-col class="d-none d-lg-flex">
          <nav class="nav">
              <router-link v-for="(item, i) in menu" :key="i" :to="item.href" :class="(!item.href)?'inactive':''">{{item.name}}</router-link>
              <a v-if="checkDate" :href="config.reg_link" target="_blank">РЕГИСТРАЦИЯ</a>
              <a v-if="!checkDate" target="_blank" class="inactive">РЕГИСТРАЦИЯ</a>
          </nav>
        </v-col>
    <!-- меню  -->
        <v-col class="d-flex d-lg-none justify-end mt-0 mt-sm-5 mr-1 mr-sm-5">
          <v-icon class="menu-icon" @click="drawer = true" color="#1D70B7">mdi-menu</v-icon>
        </v-col>

      </v-row>

    </v-container>

    <!-- Выезжающее меню  -->

    <v-navigation-drawer
        v-model="drawer"
        absolute
        right
        temporary
    >
      <v-list>
        <v-list-item
            v-for="(item, i) in menu"
            :key="i"
            :to="item.href"
            class="drawer"
        >
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title class="drawer">  <!--
            <a href="https://form.kkr.ru/f/202304031458-serdtse-otdayu-detyam-2023" target="_blank">РЕГИСТРАЦИЯ</a> -->
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- Подвал -->

    <router-view class="router" />

    <v-row class="footbott">
      <v-col class="text-center">
        &#169; 2024 Сердце отдаю детям
      </v-col>
    </v-row>
  </v-app>
</template>


<script>
import axios from 'axios'

export default {
  data() {
    return {
        drawer: false,
        menu: [
          {
            name: "О КОНКУРСЕ",
            href: "/about"
          },
          {
            name: "ДОКУМЕНТЫ",
            href: "/docs"
          },
          {
            name: "НОВОСТИ",
            href: "/news"
          },
          {
            name: "УЧАСТНИКИ",
            href: "/competitors"
          },
          {
            name: "МАТЕРИАЛЫ",
            href: "/materials"
          }
        ],
        config: null

      }
  },

  mounted() {

    axios
        .get('http://сердцедетям.рф/api/config/')
        .then(response => (this.config = response.data))
  },
  computed: {
    checkDate() {
      return ((Date.now() > (new Date(this.config.reg_open).getTime()))&&(Date.now() < (new Date(this.config.reg_close).getTime())))
    }
  },
}

</script>

<style lang="scss">

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* 1920+ 1920+ 1920+ 1920+ 1920+ 1920+ 1920+ 1920+ 1920+ 1920+ 1920+ 1920+ 1920+ 1920+ 1920+ 1920+ 1920+ 1920+ */

nav {
  font-family: "Slange Bold";
  position: relative;
  top: 55px;
  width: 640px;

  a {
    text-decoration: none;
    margin: 2px;
    color: #333 !important;
    font-size: 16px;
    padding: 8px;

  }
  a:hover {
    background-color: #dbefff;
  }
}

/* это хедер на всех страницах */

.header{
  padding: 0px;
  z-index: 2;
  height: 148px;
  box-shadow: 0 0 10px black;
  position: relative;
}

.menu {
  max-width: 1448px;
  margin: auto !important;
}

.logo {
  position: relative;
  width: 476px;
}

.logo img{
  width: 521px;
  height: 79px;
}

.menu-icon {
  font-size: 72px !important;

}

.menu-list {
  width: 100% !important;
}

.router{
  z-index: 1;
}

.inactive{
  pointer-events: none;
  color: #ccc !important;
}


@media all and (max-width: 1920px) {

  nav {
    font-family: "Slange Bold";
    position: relative;
    top: 55px;
    width: 720px;
    margin-left: auto;

    a {
      text-decoration: none;
      margin: 2px;
      color: #333 !important;
      font-size: 16px;
      padding: 8px;

    }
    a:hover {
      background-color: #dbefff;
    }
  }

  /* это хедер на всех страницах */

  .header{
    padding: 0px;
    z-index: 2;
    height: 148px;
    box-shadow: 0 0 10px black;
    position: relative;
  }

  .menu {
    max-width: 1348px;
    margin: auto !important;
  }

  .logo {
    position: relative;
    width: 421px;
  }

  .logo img{
    width: 521px;
    height: 79px;
  }

  .menu-icon {
    font-size: 72px !important;

  }

  .menu-list {
    width: 100% !important;
  }

  .router{
    z-index: 1;
  }

  .inactive{
    pointer-events: none;
    color: #ccc !important;
  }

}

/* 1440 1440 1440 1440 1440 1440 1440 1440 1440 1440 1440 1440 1440 1440 1440 1440 1440 1440 1440 1440 1440  */

@media all and (max-width: 1440px) {
  nav {
    font-family: "Slange Bold";
    position: relative;
    top: 55px;
    width: 720px;
    margin-left: auto;

    a {
      text-decoration: none;
      margin: 2px;
      color: #333 !important;
      font-size: 16px;
      padding: 8px;

    }
    a:hover {
      background-color: #dbefff;
    }
  }

  /* это хедер на всех страницах */

  .header{
    padding: 0px;
    z-index: 2;
    height: 148px;
    box-shadow: 0 0 10px black;
    position: relative;
  }

  .menu {
    max-width: 1248px;
    margin: auto !important;
  }

  .logo {
    position: relative;
  }

  .logo img{
    max-width: 521px;
    height: 79px;
  }

  .menu-icon {
    font-size: 72px !important;
  }

  .menu-list {
    width: 100% !important;
  }

  .router{
    z-index: 1;
  }

  .inactive{
    pointer-events: none;
    color: #ccc !important;
  }
}

/* 1024 1024 1024 1024 1024 1024 1024 1024 1024 1024 1024 1024 1024 1024 1024 1024 1024 1024 1024 1024 1024 1024 */

@media all and (max-width: 1024px) {

  nav {
    font-family: "Slange Bold";
    position: relative;
    top: 45px;
    width: 547px;
    margin-left: auto;

    a {
      text-decoration: none;
      margin: 2px;
      color: #333 !important;
      font-size: 14px;
      padding: 8px;

    }
    a:hover {
      background-color: #dbefff;
    }
  }

  /* это хедер на всех страницах */

  .header{
    padding: 0px;
    z-index: 2;
    height: 132px;
    box-shadow: 0 0 10px black;
    position: relative;
  }

  .menu {
    max-width: 1248px;
    margin: auto !important;
  }

  .logo {
    position: relative;
    width: 276px;
  }

  .logo img{
    width: 359px;
    height: 59px;
  }

  .menu-icon {
    font-size: 72px !important;
  }

  .menu-list {
    width: 100% !important;
  }

  .router{
    z-index: 1;
  }

  .inactive{
    pointer-events: none;
    color: #ccc !important;
  }
}

/* 800 800 800 800 800 800 800 800 800 800 800 800 800 800 800 800 800 800 800 800 800 800 800 800 800 800 800 */

@media all and (max-width: 800px) {
  nav {
    font-family: "Slange Bold";
    position: relative;
    top: 45px;
    width: 547px;
    margin-left: auto;

    a {
      text-decoration: none;
      margin: 2px;
      color: #333 !important;
      font-size: 14px;
      padding: 8px;

    }
    a:hover {
      background-color: #dbefff;
    }
  }

  /* это хедер на всех страницах */

  .header{
    padding: 0px;
    z-index: 2;
    height: 132px;
    box-shadow: 0 0 10px black;
    position: relative;
  }

  .menu {
    max-width: 1248px;
    margin: auto !important;
  }

  .logo {
    position: relative;
    width: 276px;
  }

  .logo img{
    width: 359px;
    height: 55px;
  }

  .menu-icon {
    font-size: 72px !important;
  }

  .menu-list {
    width: 100% !important;
  }

  .router{
    z-index: 1;
  }

  .inactive{
    pointer-events: none;
    color: #ccc !important;
  }

}

/* 600 600 600 600 600 600 600 600 600 600 600 600 600 600 600 600 600 600 600 600 600 600 600 600 600 600 600 600 */

@media all and (max-width: 600px) {
  nav {
    font-family: "Slange Bold";
    position: relative;
    top: 45px;
    width: 547px;
    margin-left: auto;

    a {
      text-decoration: none;
      margin: 2px;
      color: #333 !important;
      font-size: 14px;
      padding: 8px;

    }
    a:hover {
      background-color: #dbefff;
    }
  }

  /* это хедер на всех страницах */

  .header{
    padding: 0px;
    z-index: 2;
    height: 100px;
    box-shadow: 0 0 10px black;
    position: relative;
  }

  .menu {
    max-width: 1248px;
    margin: auto !important;
  }

  .logo {
    position: relative;
    width: 276px;
  }

  .logo img{
    width: 359px;
    height: 55px;
  }

  .menu-icon {
    font-size: 72px !important;
  }

  .menu-list {
    width: 100% !important;
  }

  .router{
    z-index: 1;
  }

  .inactive{
    pointer-events: none;
    color: #ccc !important;
  }

}

/* 420 420 420 420 420 420 420 420 420 420 420 420 420 420 420 420 420 420 420 420 420 420 420 420 420 420 */

@media all and (max-width: 420px) {
  nav {
    font-family: "Slange Bold";
    position: relative;
    top: 45px;
    width: 547px;
    margin-left: auto;

    a {
      text-decoration: none;
      margin: 2px;
      color: #333 !important;
      font-size: 14px;
      padding: 8px;

    }
    a:hover {
      background-color: #dbefff;
    }
  }

  /* это хедер на всех страницах */

  .header{
    padding: 0px;
    z-index: 2;
    height: 73px;
    box-shadow: 0 0 10px black;
    position: relative;
  }

  .menu {
    max-width: 1248px;
    margin: auto !important;
  }

  .logo {
    position: relative;
    width: 220px;
  }

  .logo img{
    width: 240px;
    height: 36px;
  }

  .menu-icon {
    font-size: 42px !important;
  }

  .menu-list {
    width: 100% !important;
  }

  .router{
    z-index: 1;
  }

  .inactive{
    pointer-events: none;
    color: #ccc !important;
  }
}

/* выползающее меню уберем максимально сильно вниз чтобы не путалось под ногами */

.drawer a{
  text-decoration: none;
}

/* это для футера на всех страницах - - - уберем вниз потому что он и так внизу */

.footbott{
  font-family: "Slange Bold";
  color: #ffffff;
  font-size: 14px;
  background-color: #A81217;
  text-align: center;
  vertical-align: middle;
  padding: 20px;
}

/* джва шрифта джва шрифта джва шрифта */

@font-face {
  font-family: "Slange Bold";
  src: local("Slange Bold"), url(./assets/fonts/ALSSchlangesans-Bold.otf) format("truetype");
}
@font-face {
  font-family: "Roboto Light";
  src: local("Roboto Light"), url(./assets/fonts/Roboto-Light_0.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto Medium";
  src: local("Roboto Medium"), url(./assets/fonts/Roboto-Medium_0.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto Regular";
  src: local("Roboto Regular"), url(./assets/fonts/Roboto-Regular_0.ttf) format("truetype");
}

</style>
